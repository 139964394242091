<template>
  <div>
    <b-modal id="modalPrintLabel" centered modal-class="modal-warning dialog-600 custom-dialog" hide-footer>
      <template #modal-title>
        <div class="title-modal">
          <feather-icon icon="PrinterIcon" size="20" class="mr-1"/><h4 class="mb-0">Imprimir {{ showCounter ? 'etiquetas' : 'etiqueta'}}</h4>
        </div>
      </template>
      <div class="mt-0 text-center">
        <span v-if="showCounter"> <strong>{{deliveriesIds.length}} envío(s) seleccionados</strong> </span> 
        <h3 class="mt-2">Seleccione tipo de impresión</h3>
        <!-- {{deliveriesIds}}, {{list}} {{carrierCodes}} -->
        <div class="container my-2">
          <div class="row d-flex justify-content-around">
            <b-button class="col-md-3" variant="outline-light" @click="selectPrintType(1, 'buttonZebra')"> 
              <feather-icon v-if="loading.buttonZebra" icon="LoaderIcon"  size="20" class="spinner"/>
              <span v-else>{{$t('Zebra')}}</span>
            </b-button>
            <b-button class="col-md-3" variant="outline-light" @click="selectPrintType(2, 'buttonLetter')"> 
              <feather-icon v-if="loading.buttonLetter" icon="LoaderIcon"  size="20" class="spinner"/>
              <span v-else>{{$t('Hoja Carta')}}</span>
            </b-button>
            <b-button class="col-md-3" variant="outline-light" @click="selectPrintType(3, 'buttonZPL')"> 
              <feather-icon v-if="loading.buttonZPL" icon="LoaderIcon" size="20" class="spinner"/>
              <span v-else>{{$t('ZPL')}}</span>
            </b-button>
          </div>
        </div>
        <b-embed type="iframe" v-if="label && label.show" aspect="16by9" allowfullscreen name="myFrame" id="myFrame" :src="labelUrl"></b-embed>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { BEmbed } from 'bootstrap-vue'
import EnviaFlashService from '../../enviaFlash.service.js'
export default {
  components: { BEmbed },
  name: 'modal-print-label',
  props:['label', 'deliveriesIds', 'showCounter'],
  data() {
    return {
      loading: {
        buttonZebra: false,
        buttonLetter: false,
        buttonZPL: false
      },
      enviaFlashService: new EnviaFlashService()
    }
  },
  computed: {
    labelUrl() {
      return this.label?.url || ''
    }
  },
  watch: {},
  
  methods: {
    selectPrintType (type, loading) {
      const data = {
        print_type: type,
        delivery_ids: this.deliveriesIds
      }
      this.loading[loading] = true
      this.printLabels(data, loading)
    },
    printLabels (qry, loading) {
      this.enviaFlashService.callService('assignToSummary', qry, qry)
        .then(resp => {
          this.$emit('sendLabelResponse', resp.data)
        })
        .catch(error => {
          this.$alert(this.$t('msg-problem-deliver-label', {code: error}))
        })
        .finally(_ => {
          this.loading[loading] = false
        })
    }
  }
}
</script>
<style lang="scss">
  .title-modal {
    display: flex;
    justify-content: center;
    .feather {
      color: #4a7bbe;
    }
  }  
</style>