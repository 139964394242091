export default {
  cl: {
    required: [3],
    display: [3]
  },
  co: {
    required: [2],
    display: [1, 2]
  },
  pe: {
    required: [3],
    display: [2, 3]
  },
  ar: {
    required: [3],
    display: [3]
  },
  mx: {
    required: [4],
    display: [1, 2, 4]
  },
  ec: {
    required: [3],
    display: [1, 2, 3]
  },
  uy: {
    required: [2],
    display: [1, 2]
  },
  tl: {
    required: [4],
    display: [1, 2, 3, 4]
  }
}