<template>
  <div>
    <b-card>
      <filter-swapper id="list_swapper" :trigger="selectedRows.length === 0" :buttons="buttons" :controlHeightButtons="controlHeight">
        <template #slot1>
          <form-render :form.sync="form" :key="keyFormRender" :fields="fields" @send="filterList" :buttonSend="buttonSend" ref="formFilter"
            containerButtonsClass="col-sm-12 col-md-7 col-lg-4 col-xl-3 mt-2" class="mb-2">
            <template #buttons>
              <b-button variant="outline-light" v-b-tooltip.hover title="Limpiar filtros" class="ml-1" @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/></b-button>
            </template>
          </form-render>
        </template>
      </filter-swapper>
      <div v-show="!loading.skeletonTable">
        <table-render :schema="schema" :rows="rows" :actions="actions" :showCheckboxes="true" :selectedRows.sync="selectedRows" :loading="loading.deliveries" ></table-render>
        <pagination :pagination="pagination" :noDigits="false" :showSize="true"/>
      </div>
      <div class="table-render-skeleton" v-if="loading.deliveries && loading.skeletonTable">
        <b-skeleton-table
          :rows="pagination.limit || 5"
          :columns="schema.length || 10"
          :table-props="{ }"/>
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'list',
  props: ['tab', 'skeletonTable'],
  data() {
    return {
      selectedRows: [],
      controlHeight: { class: 'row mb-2 spacing-label-field' },
      buttonSend: { title: 'Buscar', icon: 'SearchIcon', color: 'warning' },
      keyFormRender: 0,
      form: {},
      filters: {},
      fields: [
        { fieldType: 'FieldInput', label: 'N° de ticket, de envío', name: 'imported_id', containerClass: 'col-md-2 container-info col-sm-12' },
        { fieldType: 'FieldDatepicker', label: 'Fecha de búsqueda', name: 'find_date', range: true, clearable: true, containerClass: 'container-info col-sm-12 col-md-3'}
      ],
      buttons: [],
      schema: [],
      rows: [],
      actions: [],
      pagination: {
        page: 1,
        total: 0,
        limit: 10
      },
      loading: {
        deliveries: true,
        total: true,
        first: true,
        skeletonTable: false,
        loadingTable: false
      }
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      deliveries: 'getDeliveriesFlash',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    tab () {
      this.setTab(this.tab)
    },
    mySession () {
      this.session_user = {
        country: this.mySession.country, 
        role: this.mySession.role,
        organization: this.mySession.organization ? this.mySession.organization : null,
        shipper: this.mySession.shipper ? this.mySession.shipper : null
      }
    },
    deliveries () {
      this.rows = this.deliveries.rows
      this.pagination.total = this.deliveries.total
    },
    'pagination.page' () {
      this.getDeliveries(this.tab)
    },
    'pagination.limit' () {
      this.getDeliveries(this.tab)
    },
    skeletonTable () {
      this.loading.skeletonTable = this.skeletonTable
    },
    generalLoading: {
      handler () {
        this.loading.deliveries = !!this.generalLoading.getDeliveriesFlash
      },
      deep: true
    },
    loading: {
      handler () {
        const prevTotal = this.loading.total
        this.loading.total = !Object.keys(this.loading).filter(key => key !== 'total').every(key => !this.loading[key])
        if (prevTotal !== this.loading.total) this.keyFormRender++
      },
      deep: true
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      if (this.tab) this.setTab(this.tab)
    },
    setTab (tab) {
      this.actions = []
      this.schema = []
      this.selectedRows = []
      switch (tab) {
      case 1:
        this.schema = [
          {label: 'Seller', key: 'shipper_name', sortable: true},
          {label: 'Estado', key: 'status_name', sortable: true, class: ['text-center']},
          {label: 'N° de envío', key: 'imported_id', sortable: true},
          {label: 'Cliente', key: 'customer', sortable: true},
          {label: 'Comuna', key: 'place', sortable: true},
          {label: 'Fecha creación', key: 'created_at', sortable: true, wrap: true},
          {label: 'Acciones', key: 'actions', class: 'text-center', style: { width: '10%'}}
        ]
        this.actions = [
          {action: id => this.confirmDeleteDelivery(id), icon: 'TrashIcon', color: 'danger', text: 'Eliminar envío'}
        ]
        break
      case 2:
        this.schema = [
          {label: 'Seller', key: 'shipper_name', sortable: true},
          {label: 'Estado', key: 'status_name', sortable: true, class: ['text-center']},
          {label: 'N° de envío', key: 'imported_id', sortable: true},
          {label: 'Cliente', key: 'customer', sortable: true},
          {label: 'Comuna', key: 'place', sortable: true},
          {label: 'Fecha creación', key: 'created_at', sortable: true, wrap: true},
          {label: 'Acciones', key: 'actions', class: 'text-center', style: { width: '10%'}}
        ]
        this.actions = [
          {action: id => this.confirmDeleteDelivery(id), icon: 'TrashIcon', color: 'danger', text: 'Eliminar envío'}
        ]
        break
      case 3:
        this.schema = [
          {label: 'Seller', key: 'shipper_name', sortable: true},
          {label: 'Estado', key: 'status_name', sortable: true, class: ['text-center']},
          {label: 'N° de envío', key: 'imported_id', sortable: true},
          // {label: 'Flash', key: 'flash_icon', sortable: true},
          {label: 'Cliente', key: 'customer', sortable: true},
          {label: 'Comuna', key: 'place', sortable: true},
          {label: 'Courier', key: 'carrier_name', sortable: true, wrap: true},
          {label: 'Fecha creación', key: 'created_at', sortable: true, wrap: true},
          // {label: 'Fecha de entrega', key: 'deadline_date', sortable: true, wrap: true},
          {label: 'Acciones', key: 'actions', class: 'text-center', style: { width: '10%'}}
        ]
        this.actions = [
          {action: id => this.confirmGenerateLabel(id), icon: 'TagIcon', color: 'primary', text: 'Generar etiqueta'},
          {action: id => this.confirmCancelDelivery(id), icon: 'XCircleIcon', color: 'danger', text: 'Cancelar envío'}
        ]
        break
      }
      this.buttons = [{ name: 'delete', text: 'Eliminar', color: 'danger', icon: 'TrashIcon' }]
    },
    filterList (data) {
      this.filters = {}
      Object.keys(data).map(key => {
        switch (key) {
        case 'find_date':
          if (data[key] !== null) {
            this.filters['created_at_from'] = this.$options.filters.moment(data[key].start)
            this.filters['created_at_to'] = this.$options.filters.moment(data[key].end)
          }
          break
        default:
          if (data[key] !== null) this.filters[key] = data[key].id || data[key]
          break
        }
      })
      if (this.pagination.page !== 1) this.pagination.page = 1
      else this.getDeliveries(this.tab)
    },
    cleanFilter (e) {
      this.form = {}
      this.filters = {}
      this.getDeliveries(this.tab)
    },
    getDeliveries(tab) {
      if (tab === 0) return
      this.loading.loadingTable = true
      const queryParams = {
        ...this.filters,
        delivery_type: 'flash',
        country_code: this.session_user?.country?.code,
        limit: this.pagination.limit,
        page: this.pagination.page
      }
      switch (tab) {
      case 1: 
        queryParams.flash_status = 'rejected'
        break
      case 2: 
        queryParams.flash_status = 'created'
        break
      case 3:
        queryParams.flash_status = 'assigned'
        break
      default:
        break
      }
      this.$store.dispatch('fetchService', { name: 'getDeliveriesFlash', queryParams, onSuccess: () => this.loading.loadingTable = false })
    }
  }
}
</script>