<template>
  <div>
    <b-card>
      <b-tabs align="left" class="tab-primary" v-model="tabIndex" @input="getDeliveries(null, true)">
        <b-tab >
          <template #title>
            <div class="tab-title">
              <feather-icon icon="SendIcon" />
              <span> {{$t('Crear envíos')}} </span>
            </div>
          </template>
          <create-delivery></create-delivery>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="tab-title">
              <!-- <feather-icon icon="SendIcon" /> -->
              <span> {{$t('Fallidos')}} </span>
            </div>
          </template>
          <list-failed :deliveries="deliveries_1" @updateList="getDeliveries" :skeletonTable="loading.skeletonTable"></list-failed>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="tab-title">
              <!-- <feather-icon icon="SendIcon" /> -->
              <span> {{$t('Por asignar')}} </span>
            </div>
          </template>
          <list-to-assign :deliveries="deliveries_2" @updateList="getDeliveries" :skeletonTable="loading.skeletonTable"></list-to-assign>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="tab-title">
              <!-- <feather-icon icon="SendIcon" /> -->
              <span> {{$t('Asignados')}} </span>
            </div>
          </template>
          <!-- <list :tab="tabIndex" :skeletonTable="loading.skeletonTable"></list> -->
          <list-assigned :deliveries="deliveries_3" @updateList="getDeliveries" :skeletonTable="loading.skeletonTable"></list-assigned>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CreateDelivery from '../create/create-deliveries/CreateDelivery.vue'
import List from '../create/list.vue'
import ListFailed from './failed/ListFailed.vue'
import ListAssigned from './assigned/ListAssigned.vue'
import ListToAssign from './to-assign/ListToAssign.vue'
import BaseServices from '@/store/services'

export default {
  name: 'create',
  components: { CreateDelivery, ListFailed, ListAssigned, ListToAssign },
  props: [],
  data () {
    return {
      tabIndex: 0,
      deliveries: {},
      deliveries_1: {},
      deliveries_2: {},
      deliveries_3: {},
      loading: {
        skeletonTable: false
      },
      baseService: new BaseServices(this)
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      this.session_user = {
        country: this.mySession.country, 
        role: this.mySession.role,
        organization: this.mySession.organization ? this.mySession.organization : null,
        shipper: this.mySession.shipper ? this.mySession.shipper : null
      }
    }
  },
  methods: {
    getDeliveries(queryParams = null, boleanTable = null) {
      if (this.tabIndex === 0) return
      if (!queryParams) {
        queryParams = {
          limit: 10,
          page: 1,
          delivery_type: 'flash',
          country_code: this.session_user?.country?.code || 'CL'
        }
        switch (this.tabIndex) {
        case 1: 
          queryParams.flash_status = 'rejected'
          break
        case 2: 
          queryParams.flash_status = 'created'
          break
        case 3:
          queryParams.flash_status = 'assigned'
          break
        default:
          break
        }
      }
      if (boleanTable) this.loading.skeletonTable = boleanTable
      this.deliveries = {}
      this.baseService.callService('getDeliveriesFlash', queryParams)
        .then(resp => {
          this.deliveries = {
            rows: resp.data.map(delivery => {
              return {
                ...delivery,
                imported_id: delivery.order.imported_id,
                shipper_name: delivery.shipper.name,
                status_name: delivery.status.name,
                // flash_icon: delivery.
                customer: delivery.destination.contact.name,
                place: delivery.destination.address.level3,
                carrier_name: delivery.carrier?.name || null,
                tracking_number: delivery.carrier?.tracking_number,
                created_at: this.$options.filters.moment(delivery.created_at, 'DD/MM/YYYY HH:mm')
                // label: { url: 'https://storage.googleapis.com/hs-carrier-deliveries-prod/202305/2279/blx_20230525_13_27_35_970807510.pdf'}
              }
            }),
            meta: { pagination: resp.meta.pagination },
            total: resp.meta.pagination.total
          }
          switch (this.tabIndex) {
          case 1: 
            this.deliveries_1 = { ...this.deliveries }
            break
          case 2: 
            this.deliveries_2 = { ...this.deliveries }
            break
          case 3:
            this.deliveries_3 = { ...this.deliveries }
            break
          default:
            break
          }
          if (boleanTable) this.loading.skeletonTable = false
        })
    }
  }
}
</script>
<style lang="scss"></style>