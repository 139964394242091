import { environment } from '@/environments/environment'
import useJwt from '@/auth/jwt/useJwt'

const services = [
  { name: 'getLevels', url: `${environment.dubaiApiUrl}/countries/{country}/levels`, method: 'get'},
  { name: 'getWarehousesFlash', url: `${environment.beijingApiUrl}/filters/warehouses`, method: 'get'},
  { name: 'getCarriersFlash', url: `${environment.beijingApiUrl}/shippers/{shipper_id}/configuration`, method: 'get'},
  { name: 'assignToSummary', url: `${environment.estambulApiUrl}/deliveries/assign-to-summary`, method: 'put'}
]

export default class EnviaFlashService {
  constructor(context) {
    this.context = context
  }
  async callService (name, queryParams = {}, params = {}) {
    const service = services.filter(service => service.name === name)[0]
    return useJwt.genericService(service, queryParams, params)
  }
  
  async callMultipleServices (callArray, byName = false) {
    const servicesArray = callArray.map(call => ({ service: services.filter(service => service.name === call.name)[0], ...call }))
    return useJwt.genericMultipleService(servicesArray, byName)
  }
}