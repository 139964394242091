<template>
  <div id="container">
    <div class="row">
      <div class="col-md-6 col-sm-6 mt-1">
        <form-render :fields="fields1" :form.sync="form" :key="`formRenderDelivery1_${keyFormRender1}`" ref="formRenderDelivery1">
          <template #originTitleSlot>
            <div class="letter-small">
              <h3>{{$t('Datos de Origen')}}</h3><h6>{{$t('(Dirección donde se encuentra el envío a retirar)')}}</h6>
            </div>
          </template>
          <template #destinyTitleSlot>
            <div class="letter-small">
              <h3>{{$t('Datos de Destino')}}</h3><h6>{{$t('(Dirección donde ser entregado el envío)')}}</h6>
            </div>
          </template>
        </form-render>
      </div>
      <div class="col-md-6 col-sm-6 mt-1">
        <form-render :fields="fields2" :form.sync="form" :key="`formRenderDelivery2_${keyFormRender2}`" ref="formRenderDelivery2">
          <template #distributionTitleSlot>
            <div class="letter-small">
              <h3>{{$t('Datos de distribución')}}</h3><h6>{{$t('(Encargado de la distribución del envío)')}}</h6>
            </div>
          </template>
          <template #carriersSlot>
            <div>
              <label>{{$t('Couriers activos')}}</label>
            </div>
            <div class="carriers-images" v-if="showCarriersActive">
              <div v-for="(carrier, index) in carriers.filter(el => el.enabled === true)" :key="index" class="text-center p-1">
                <b-img :src="carrier.logo" :alt="carrier.name" :style="{'max-width': carrier.code === 'RPP' ? '100px' : '130px', 'max-height': '90px', 'margin': 'auto'}"/>
              </div>
            </div>
            <div class="pt-1 pb-1" v-if="!showCarriersActive">
              <h4>{{$t('Esta company no tiene couriers activos')}}</h4>
            </div>
          </template>
          <template #packagesSizeSlot>
            <div>
              <label>{{$t('Tamaño')}}</label>
            </div>
          </template>
        </form-render>
      </div>
      <div class="col-md-12 col-sm-12 mt-1" v-show="!!mySession.id">
        <b-button ref="createFlashDelivery" variant="warning" class="push-right" @click="validateForm" :disabled="loading.buttonSend"> 
          <feather-icon v-if="loading.buttonSend" icon="LoaderIcon" class="spinner"/>
          {{$t('Crear envío')}}
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import internalLevels from '@/views/modules/envia-flash/create/internalLevels.js'
import { mapGetters } from 'vuex'
import EnviaFlashService from '../../enviaFlash.service.js'
import Swal from 'sweetalert2'
export default {
  name: 'create-deliveries',
  data () {
    return {
      session_user: {},
      form: {},
      fields1: [],
      fields2: [],
      keyFormRender1: 0,
      keyFormRender2: 0,
      selectorsLevel: {},
      optionsPackagesSize: [
        {id: 1, text: 'Sobre', content: '15 x 10 x 5 cm', img: require('@/assets/images/images-on-components/packages-size/package-size-letter.png'), contentStyles: 'font-size: 12px; color: #BBB', imgStyles: 'height: 20px', default: true},
        {id: 2, text: 'Pequeño', content: '30 x 20 x 20 cm', img: require('@/assets/images/images-on-components/packages-size/package-size-small.png'), contentStyles: 'font-size: 12px; color: #BBB', imgStyles: 'height: 35px'},
        {id: 3, text: 'Mediano', content: '35 x 35 x 35 cm', img: require('@/assets/images/images-on-components/packages-size/package-size-medium.png'), contentStyles: 'font-size: 12px; color: #BBB', imgStyles: 'height: 48px'}
      ],
      loading : {
        organizations: true,
        levels: true,
        warehouses: true,
        shippersByOrganization: true,
        buttonSend: false
      },
      enviaFlashService: new EnviaFlashService(this),
      maxPricesByCountry: {
        1:{ 
          profile: {
            cenco: { warning: 500000, alert: 999999 },
            normal: { warning: 500000, alert: null }
          } 
        }
      }
    }
  },

  mounted() {
    if (this.mySession.id) this.setInitialData()
  },

  computed: {
    ...mapGetters({
      mySession: 'getSession',
      organizations: 'getOrganizationsGlobal',
      levels: 'getLevels',
      warehouses: 'getWarehousesFlash',
      carriers: 'getCarriersFlash',
      shippersByOrganization: 'getShippersByOrganizationSimplified'
    })
  },

  watch: {
    generalLoading: {
      handler() {
        this.loading.organizations = !!this.generalLoading.getOrganizationsGlobal
        this.loading.levels = !!this.generalLoading.getLevels
        this.loading.carriers = !!this.generalLoading.getCarriersFlash
        this.loading.warehouses = !!this.generalLoading.getWarehousesFlash
        this.loading.shippersByOrganization = !!this.generalLoading.shippersByOrganization
      },
      deep: true
    },
    mySession () {
      this.session_user = {
        country: this.mySession.country, 
        role: this.mySession.role,
        organization: this.mySession.organization ? this.mySession.organization : null,
        shipper: this.mySession.shipper ? this.mySession.shipper : null
      }
      this.setInitialData()
    },
    organizations () {
      this.setSelectOptions('organization', { options: this.organizations })
    },
    shippersByOrganization () {
      this.setSelectOptions('shipper', { options: this.shippersByOrganization })
    },
    warehouses () {
      this.setSelectOptions('warehouse', { options: this.warehouses })
    },
    carriers () {
      Array.isArray(this.carriers) && this.carriers.length !== 0 ? this.showCarriersActive = true : this.showCarriersActive = false
      const index = this.fields2.findIndex(el => el.name === 'distributionTitleSlot')
      this.fields2.splice(index + 1, 0, { name: 'carriersSlot', useSlot: true, containerClass: 'container-info col-sm-12 col-md-12' })
    },
    levels () {
      this.allPlacesLevel3(this.levels)
    },
    'form.price' (price) {
      //only for Chile in the meantime 
      const countryId = this.form.shipper?.country_id 
      if (countryId === 1) this.checkPrice(price, countryId)
    }
  },

  methods: {
    setInitialData () {
      this.fields1 = [
        { name: 'originTitleSlot', useSlot: true, containerClass: 'container-info col-sm-12 col-md-12' },
        { fieldType: 'FieldSelect', name: 'shipper', label: 'Empresa', useLabel: true, containerClass: 'container-info col-sm-12 col-md-6', change: this.changeShipper, validation: 'required' },
        { fieldType: 'FieldSelect', name: 'warehouse', label: 'Lugar de retiro / Bodega', useLabel: true, containerClass: 'container-info col-sm-12 col-md-6', validation: 'required', change: this.changeWarehouse, dependency: 'shipper', searchOnType: { fx: this.getWarehousesOnSearch, nChars: 3, debounce: 600 }},
        { name: 'line0', useSlot: true, useSkeleton: true, skipLine: true, containerClass: 'container-info col-sm-12 col-md-6' },
        { name: 'destinyTitleSlot', useSlot: true, containerClass: 'container-info col-sm-12 col-md-12' },
        { fieldType: 'FieldInput', name: 'name', label: 'Nombre', containerClass: 'container-info col-sm-12 col-md-6', validation: 'required'},
        { fieldType: 'FieldInput', name: 'phone', label: 'Telefono', containerClass: 'container-info col-sm-12 col-md-6', validation: 'required', prepend : {text: `+${ this.session_user?.country?.phone_code ? this.session_user?.country?.phone_code : 56 }`, color: '#BBBBBB'}},
        { fieldType: 'FieldInput', name: 'email', label: 'Correo', containerClass: 'container-info col-sm-12 col-md-6', validation: 'required|email', iconLeft: {icon: 'MailIcon', size: '20', stroke: 1.5, color: '#BBBBBB'} },
        { name: 'line0', useSlot: true, useSkeleton: true, skipLine: true, containerClass: 'container-info col-sm-12 col-md-3' },
        { fieldType: 'FieldSelect', name: 'level3', label: 'Comuna', containerClass: 'container-info col-sm-12 col-md-6', validation: 'required', dependency: 'shipper'},
        { fieldType: 'FieldInput', name: 'full_address', label: 'Dirección', containerClass: 'container-info col-sm-12 col-md-12', validation: 'required'},
        { fieldType: 'FieldTextarea', name: 'information', label: 'Observaciones', containerClass: 'col-sm-12 container-info' }
      ]
      this.fields2 = [
        { name: 'distributionTitleSlot', useSlot: true, containerClass: 'container-info col-sm-12 col-md-12' },
        // { name: 'carriersSlot', useSlot: true, containerClass: 'container-info col-sm-12 col-md-12' },
        { fieldType: 'FieldInput', name: 'imported_id', label: 'Nº de referencia', containerClass: 'container-info col-sm-12 col-md-6', validation: 'required'},
        { fieldType: 'FieldInput', name: 'n_packages', label: 'Bultos', containerClass: 'container-info col-sm-12 col-md-2', validation: 'required', dependency: true },
        { fieldType: 'FieldInput', name: 'price', label: 'Valor declarado', append: {text: 'CLP', color: '#BBBBBB'}, containerClass: 'container-info col-sm-12 col-md-4', validation: 'required'},
        // { name: 'packagesSizeSlot', useSlot: true, containerClass: 'container-info col-sm-12 col-md-12' },
        { fieldType: 'FieldRadio', label: 'Tamaño', name: 'packageSize', align: 'h', containerClass: 'col-sm-12 col-md-12 container-info mt-2 mb-1', validation: 'required', customRadio: true, options: this.optionsPackagesSize, change: this.changeSizePackage},
        { fieldType: 'FieldRange', name: 'weight', label: 'Peso del envío', type: 'number', containerClass: 'container-info col-sm-12 col-md-12 mb-2', validation: 'required', specsRange: { min: 1, max: 10, label: 'Kg'}},
        { fieldType: 'FieldTextarea', name: 'description', label: 'Descripción del producto', containerClass: 'col-sm-12 container-info', validation: 'required' }
      ]
      if (['admin', 'superadmin'].includes(this.session_user.role)) {
      // if (['marketplace'].includes(this.session_user.role)) {
        const index = this.fields1.findIndex(el => el.name === 'originTitleSlot')
        this.fields1.splice(index + 1, 0, { fieldType: 'FieldSelect', name: 'organization', label: 'Organización', containerClass: 'container-info col-sm-12 col-md-6', change: this.changeOrganization })
        this.fields1[index + 2].dependency = 'organization'
      } else if (['marketplace'].includes(this.session_user.role)) {
      // } else if (['admin', 'superadmin'].includes(this.session_user.role)) {
        // this.organization = {id: 35, text: 'Enviame Prueba'}
        this.organization = this.session_user?.organization
        this.changeOrganization('Mounted marketplace', this.organization)
      } else if (['seller', 'ecommerce'].includes(this.session_user.role)) {
        this.form.shipper = { ...this.session_user.shipper, text: this.session_user.shipper.name1 }
        const index = this.fields1.findIndex(el => el.name === 'shipper')
        this.fields1[index].dependency = 'organization'
        // const queryParams = { shipper_id: this.form.shipper.id }
        const services = [
          { name: 'getLevels', params: { country: this.session_user?.country?.code || 'CL' } },
          // { name: 'getWarehousesFlash', params: {}, queryParams },
          { name: 'getCarriersFlash', params: { country: this.session_user?.country?.code || 'CL', shipper_id: this.form.shipper.id } }
        ]
        this.$store.dispatch('fetchMultipleServices', { services })
      }
      if (!!this.organizations && this.organizations.length !== 0) { 
        this.setSelectOptions('organization', { options: this.organizations })
      }
      if (!!this.warehouses && this.warehouses.length !== 0) this.setSelectOptions('warehouse', { options: this.warehouses })
      this.form.n_packages = 1
      this.form.packageSize = this.optionsPackagesSize[1]
      this.form.weight = 1
      this.keyFormRender1++
      this.keyFormRender2++
    },
    changePlaceLevel (name, value, next) {
      const index = this.fields1.findIndex(el => el.name === next)
      if (next) this.fields1[index].options = value[next].sort((a, b) => a.name > b.name ? 1 : -1)
    },
    loadLevels (country, levels) {
      const fields = this.fields1.filter(el => el.name.indexOf('level') !== -1)
      this.selectorsLevel = {}
      const requiredLevels = internalLevels[country.code.toLowerCase()].required || []
      const displayLevels = internalLevels[country.code.toLowerCase()].display
      this.selectorsLevel = this.$generatePlacesLevels(this.$generateArrayPlacesFromTree(levels), Math.max(...requiredLevels), displayLevels)
      this.requiredLevels = requiredLevels
      this.addPlacesLevelFields(country)
    },
    addPlacesLevelFields (country) {
      const texts = this.$i18nImport(`${country.code.toLowerCase()}.es`)[`${country.code.toLowerCase()}.es`]
      let indexSplice = this.fields1.findIndex(el => el.name === 'email') + 2
      Object.keys(this.selectorsLevel).map((key, index) => {
        const myField = { fieldType: 'FieldSelect', name: key, label: texts[`Place_${key}`], containerClass: 'col-sm-12 container-info col-md-6', options: this.selectorsLevel[key].sort((a, b) => a.name > b.name ? 1 : -1) }
        if (index !== 0) {
          myField.dependency = Object.keys(this.selectorsLevel)[index - 1]
        }
        if (index !== Object.keys(this.selectorsLevel).length - 1) {
          myField.change = (name, value) => this.changePlaceLevel(name, value, Object.keys(this.selectorsLevel)[index + 1])
        } else {
          myField.change = (name, value) => this.changePlaceLevel(name, value, Object.keys(this.selectorsLevel)[index + 1])
        }
        myField.validation = (this.requiredLevels || []).includes(parseInt(key.split('level')[1])) ? 'required' : ''
        const indexFields = this.fields1.findIndex(el => el.name === myField.name)
        if (indexFields === -1) {
          this.fields1.splice(indexSplice, 0, myField)
        }
        this.form[key] = this.selectorsLevel[key].filter(e => e.text === this.form[`${key}Text`])[0]
        indexSplice++
      })
    },
    setSelectOptions (name, { options }) {
      const index = this.fields1.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fields1[index].options = options
        this.keyFormRender1++
      }
    },
    changeWarehouse (name, value) {
      this.fields1 = this.fields1.filter(el => !['addressWarehouse', 'phoneWarehouse', 'emailWarehouse'].includes(el.name))
      const index = this.fields1.findIndex(el => el.name === 'warehouse')
      if (value === null) {
        this.fields1[index].options = []
        delete this.form.addressWarehouse
        delete this.form.phoneWarehouse
        delete this.form.emailWarehouse
      } else {
        this.fields1.splice(index + 1, 0, 
          { fieldType: 'FieldInput', name: 'addressWarehouse', label: 'Dirección bodega (calle, numero)', containerClass: 'container-info col-sm-12 col-md-12', validation: 'required', dependency: 'true' },
          { fieldType: 'FieldInput', name: 'phoneWarehouse', label: 'Telefono bodega', containerClass: 'container-info col-sm-12 col-md-6', validation: 'required', prepend : { text: `+${ this.session_user?.country?.phone_code ? this.session_user?.country?.phone_code : 56 }`, color: '#BBBBBB'}, dependency: 'true' },
          { fieldType: 'FieldInput', name: 'emailWarehouse', label: 'Correo bodega', containerClass: 'container-info col-sm-12 col-md-6', validation: 'required|email', iconLeft: {icon: 'MailIcon', size: '20', stroke: 1.5, color: '#BBBBBB'}, dependency: 'true' }
        )
        this.form = {
          ...this.form,
          addressWarehouse: value?.address?.full_address || null,
          phoneWarehouse: value?.contact?.phone || null, 
          emailWarehouse: value?.contact?.email || null
        } 
      }
    },
    changeOrganization (name, value) {
      this.form = {
        ...this.form,
        shipper: null,
        addressWarehouse: null,
        phoneWarehouse: null,
        emailWarehouse: null
      }
      this.fields1 = this.fields1.filter(el => !['addressWarehouse', 'phoneWarehouse', 'emailWarehouse'].includes(el.name))
      const index = this.fields1.findIndex(el => el.name === 'shipper')
      this.fields1[index].options = []
      if (!value?.id) {
        return null
      }
      const queryParams = {
        page: 1,
        paginate_by: 99999
      }
      const params = {
        organization_id: !!value ? value.id : this.form.organization?.id || 0
      }
      this.fields1[index].useSkeleton = true
      this.$store.dispatch('fetchService', { name: 'getShippersByOrganizationSimplified', queryParams, params, onSuccess: () => this.fields1[index].useSkeleton = false})
    },
    changeShipper (name, value) {
      this.form = {
        ...this.form,
        warehouse: null,
        addressWarehouse: null,
        phoneWarehouse: null,
        emailWarehouse: null
      }
      this.fields1 = this.fields1.filter(el => !['addressWarehouse', 'phoneWarehouse', 'emailWarehouse'].includes(el.name))
      this.fields2 = this.fields2.filter(el => !['carriersSlot'].includes(el.name))
      if (!value?.id) {
        return null
      }
      const services = [
        { name: 'getLevels', params: { country: this.setCountryCode(value.country_id) } },
        { name: 'getCarriersFlash', params: { country: 'CL', shipper_id: this.form.shipper.id } }
      ]
      const index = this.fields1.findIndex(el => el.name === 'warehouse')
      this.fields1[index].useSkeleton = true
      this.$store.dispatch('fetchMultipleServices', { services, onSuccess: () => this.fields1[index].useSkeleton = false })
    },

    allPlacesLevel3 (levels) {
      let arrayAllPlacesLvl3 = []
      levels.map(level1 => level1.level2.map(level2 => level2.level3.map(level3 => {
        arrayAllPlacesLvl3.push({...level3, text: level3.name})
      })))
      arrayAllPlacesLvl3 = arrayAllPlacesLvl3.sort((a, b) => a.name < b.name ? -1 : 1)
      this.setSelectOptions('level3', { options: arrayAllPlacesLvl3 })
    },

    getWarehousesOnSearch (value) {
      const queryParams = {
        search: value,
        shipper_id: this.form.shipper.id,
        country_code: this.setCountryCode(this.form.shipper.country_id) || 'CL',
        activated: 1,
        validated: 1
      }
      return this.enviaFlashService.callService('getWarehousesFlash', queryParams)
        .then(resp => {
          return resp.data.map(warehouse => {
            return {
              ...warehouse,
              text: warehouse.name,
              addressWarehouse: warehouse.address.full_address,
              phoneWarehouse: warehouse.contact.phone,
              emailWarehouse: warehouse.contact.email
            }
          })
        })
    },
    
    successOnCreate(data) {
      this.loading.buttonSend = false
      Swal.fire({
        titleText: '¡Creación exitosa Envío Flash!',
        background: '#F1F9F4',
        icon: 'success',
        position: 'bottom-start',
        showConfirmButton: false,
        showCloseButton: true,
        width: '26em',
        customClass: {
          popup: 'swal2-toast'
        },
        allowOutsideClick: false
      }).then((result) => {
        // if (result.isConfirmed) {
        // }
        if (result.dismiss === Swal.DismissReason.close) {
          this.form = {}
          this.changeWarehouse('from alert', null)
          this.setInitialData()
        }
      })
    },

    errorOnCreate() {
      this.loading.buttonSend = false
      Swal.fire({
        titleText: '¡Creación fallida Envío Flash!',
        background: '#FCEDE9',
        icon: 'error',
        position: 'bottom-start',
        showConfirmButton: false,
        showCloseButton: true,
        width: '26em',
        customClass: {
          popup: 'swal2-toast'
        },
        allowOutsideClick: false
      }).then((result) => {
        // if (result.isConfirmed) {
        // }
        if (result.dismiss === Swal.DismissReason.close) {
          this.form = {}
          this.changeWarehouse('from alert', null)
          this.setInitialData()
        }
      })
    },
    setCountryCode (countryId) {
      switch (countryId) {
      case 1: 
        return 'CL'
      case 2: 
        return 'MX'
      case 3: 
        return 'CO'
      case 4: 
        return 'PE'
      case 5: 
        return 'AR'
      default:
        return null
      }      
    },

    async validateForm () {
      const value1 = await this.$refs.formRenderDelivery1.onlyCheckForm()
      const value2 = await this.$refs.formRenderDelivery2.onlyCheckForm() 
      const isCheck = [value1 && value2].every(el => el === true)
      if (isCheck) this.sendForm()
    },
    sendForm () {
      this.loading.buttonSend = true
      const payload = {
        type: 'flash',
        origin: {
          type: this.form.warehouse?.type || '',
          code: this.form.warehouse?.code || ''
        },
        destination: {
          type: 'home',
          address: {
            level3: this.form.level3?.text || '',
            full_address: this.form?.full_address || '',
            information: this.form?.information || ''
          },
          contact: {
            name: this.form?.name || '',
            email: this.form?.email || '',
            phone: this.form?.phone || ''
          }
        },
        order: {
          imported_id: this.form?.imported_id || null,
          price: this.form?.price || null,
          packages: [{
            quantity: this.form?.n_packages || 1,
            weight: this.form?.weight || 1,
            info: this.form?.description || '',
            price: this.form?.price || null
          }]
        },
        carrier: null,
        dispatch_now: true
      }
      switch (this.form.packageSize.id) {
      case 1:
        payload.order.packages[0].width = 5
        payload.order.packages[0].height = 15
        payload.order.packages[0].length = 10
        payload.order.packages[0].volume = 0.00075
        break
      case 2:
        payload.order.packages[0].width = 20
        payload.order.packages[0].height = 30
        payload.order.packages[0].length = 20
        payload.order.packages[0].volume = 0.012
        break
      case 3:
        payload.order.packages[0].width = 35
        payload.order.packages[0].height = 35
        payload.order.packages[0].length = 35
        payload.order.packages[0].volume = 0.042875
        break
      default:
        payload.order.packages[0].width = 5
        payload.order.packages[0].height = 15
        payload.order.packages[0].length = 10
        payload.order.packages[0].volume = 0.00075
        break
      }
      const data = {
        name: 'postDeliveriesFlash',
        params: { shipper_id: this.form.shipper.id },
        queryParams: payload,
        onSuccess: (resp) => this.successOnCreate(resp),
        onError:() => this.errorOnCreate()
      }
      this.$store.dispatch('fetchService', data)
    },
    isCenco(session) {
      return session?.special_profile === 'cenco'
    },
    checkPrice(price, country_id) {
      const countryId = country_id
      const limits = this.isCenco(this.mySession) ?  this.maxPricesByCountry[countryId]?.profile.cenco : this.maxPricesByCountry[countryId]?.profile.normal
 
      if (parseInt(price) > parseInt(limits?.warning)) {
        this.$warning('El precio del producto supera el valor normal de un envío', '', '!Advertencia!')
      }
      if (limits?.alert && parseInt(price) > parseInt(limits?.alert)) {
        this.$alert('El precio del producto supera el valor normal de un envío', '', 'Error')
        this.$refs.createFlashDelivery.disabled = true
      } else {
        this.$refs.createFlashDelivery.disabled = false
      }
    }
  }
}
</script>

<style lang="scss">
  .letter-small {
    display: flex;
    flex-direction: row;
    > h6 {
      font-size: 0.85rem;
      margin: 9px 0px 0px 10px;
    }
  }
  .carriers-images {
    display: flex;
    flex-direction: row;  
    align-items: center;
  }
</style>
